// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

@font-face {
  font-family: "AppFont";
  src: url("../assets/fonts/Prompt-Regular.ttf"); //change url accordingly
  //src: url("../assets/fonts/Poppins-Regular.ttf");
  }

:root {
  .popover-content.sc-ion-popover-md {
    left: 10%;
    width: 80%;
}
 // --ion-font-family: ‘AppFont’
  /** primary **/
  --ion-font-family: "AppFont";
	--ion-color-primary: #0076e4;
	--ion-color-primary-rgb: 0,118,228;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0068c9;
	--ion-color-primary-tint: #1a84e7;


	--ion-color-secondary: #e6bf5a;
	--ion-color-secondary-rgb: 230,191,90;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #caa84f;
	--ion-color-secondary-tint: #e9c56b;


  /** tertiary **/
	--ion-color-tertiary: #273a7a;
	--ion-color-tertiary-rgb: 39,58,122;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #22336b;
	--ion-color-tertiary-tint: #3d4e87;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
	--ion-color-warning: #ff8d0a;
	--ion-color-warning-rgb: 255,141,10;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #e07c09;
	--ion-color-warning-tint: #ff9823;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
